(function () {
  'use strict';

  /* @ngdoc object
   * @name calendar.championship
   * @description
   *
   */
  angular
    .module('neo.public.calendar.championship', [
      'ui.router'
    ]);
}());
